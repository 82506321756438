import classNames from "classnames";
import { navigate } from "gatsby";
import React, { useCallback, useRef, useState } from "react";
import IconLanguages from "../../images/icons/languages.svg";
import { useEscapeClick } from "../../services/use-escape-click";
import { useOutsideClick } from "../../services/use-outside-click";
import { linkResolver } from "../../utils/link-resolver";
import SelectBox from "../select-box";

export interface LanguagePickerProps {
  className?: string;
  selectedLanguage: string;
  global: Queries.GlobalFragment | null;
}

const LanguagePickerDropdown: React.FC<LanguagePickerProps> = ({
  className,
  selectedLanguage,
  global,
}) => {
  const [hidden, setHidden] = useState<boolean>(true);
  const handleLanguageClick = (languageCode: string) => {
    const url = linkResolver({ lang: languageCode });
    navigate(url);
  };
  const data = global?.prismicGlobal?.data;
  const language = data?.languages?.find(
    (item) => item?.language_key === selectedLanguage
  )?.title;
  const rootRef = useRef<HTMLDivElement>(null);
  const hideCallback = useCallback(() => {
    setHidden(true);
  }, [setHidden]);
  useOutsideClick(rootRef, hideCallback);
  useEscapeClick(rootRef, hideCallback);
  const LanguageOptions = data?.languages?.map((item, index) => {
    return (
      <SelectBox
        className="rounded-none w-full"
        onClick={() => handleLanguageClick(item?.language_key!)}
        key={index}
        selected={!!selectedLanguage && selectedLanguage === item?.language_key}
      >
        {item?.title}
      </SelectBox>
    );
  });
  return (
    <div
      ref={rootRef}
      className={classNames("relative", className)}
      onClick={() => setHidden(!hidden)}
    >
      <div
        className={classNames(
          "text-sm text-primary-300 p-2.5 flex items-center cursor-pointer hover:bg-neutral-400 rounded",
          {
            "shadow bg-neutral": !hidden,
          }
        )}
      >
        <IconLanguages className="inline-block fill-current w-5 h-5 mr-2.5" />
        {language}
      </div>
      <div
        className={classNames("mt-2 py-2 bg-white shadow rounded absolute", {
          hidden: hidden,
        })}
      >
        {LanguageOptions}
      </div>
    </div>
  );
};

export default LanguagePickerDropdown;

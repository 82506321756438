import classNames from "classnames";
import React from "react";
import { LayoutModel } from "../models/layout.model";
import Footer from "./footer";
import Navigation from "./navigation/navigation";

export interface LayoutProps {
  className?: string;
  data: LayoutModel;
}

const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({
  children,
  className,
  data,
}) => {
  const { activePage, footer, navigation, global } = data;
  return (
    <div className="flex flex-col min-h-screen">
      <Navigation
        className="sticky top-0 z-10 shadow-lg"
        activePage={activePage}
        navigation={navigation}
        global={global}
      />
      <main className={classNames("flex-grow relative", className)}>
        {children}
      </main>
      <Footer activePage={activePage} footer={footer} />
    </div>
  );
};

export default Layout;

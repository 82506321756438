import classNames from "classnames";
import { graphql, navigate, useStaticQuery } from "gatsby";
import React from "react";
import { linkResolver } from "../../utils/link-resolver";
import SelectBox from "../select-box";

export interface LanguagePickerProps {
  className?: string;
  selectedLanguage: string;
  global: Queries.GlobalFragment | null;
}

const LanguagePickerMenu: React.FC<LanguagePickerProps> = ({
  className,
  selectedLanguage,
  global,
}) => {
  const handleLanguageClick = (languageCode: string) => {
    const url = linkResolver({ lang: languageCode });
    navigate(url);
  };
  const data = global?.prismicGlobal?.data;
  const LanguageOptions = data?.languages?.map((item, index) => {
    return (
      <SelectBox
        onClick={() => handleLanguageClick(item?.language_key!)}
        key={index}
        selected={!!selectedLanguage && selectedLanguage === item?.language_key}
      >
        {item?.title}
      </SelectBox>
    );
  });
  return (
    <div className={classNames("", className)}>
      <div className="text-primary-700">{data?.languages_title}</div>
      <div className="mt-2 flex flex-row gap-4">{LanguageOptions}</div>
    </div>
  );
};

export default LanguagePickerMenu;

import classNames from "classnames";
import React from "react";

export interface SelectBoxProps {
  className?: string;
  selected: boolean;
  onClick?: () => void;
}

const SelectBox: React.FC<React.PropsWithChildren<SelectBoxProps>> = ({
  children,
  className,
  selected,
  onClick = () => {},
}) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={classNames(
        "active:bg-neutral-600 outline-none",
        "hover:bg-neutral-400 hover:text-primary-700",
        "text-primary-300 font-medium cursor-pointer",
        "py-4 px-6 border border-neutral-700 rounded",
        {
          "border-confirm text-confirm hover:text-confirm": selected,
        },
        className
      )}
    >
      {children}
    </button>
  );
};

export default SelectBox;

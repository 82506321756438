import type { HeadFC } from "gatsby";
import { graphql, PageProps } from "gatsby";
import * as React from "react";
import About from "../components/home/about";
import Banner from "../components/home/banner";
import Features from "../components/home/features";
import Pricing from "../components/home/pricing";
import Solution from "../components/home/solution";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { ActivePageModel } from "../models/active-page.model";
import { LayoutModel } from "../models/layout.model";

const Homepage: React.FC<PageProps<Queries.HomepageQuery>> = ({ data }) => {
  const pageData = data.prismicHomepage!.data!;
  const activePage: ActivePageModel = data.prismicHomepage!;
  const layoutData: LayoutModel = {
    activePage,
    global: data,
    footer: data,
    navigation: data,
  };
  return (
    <Layout className="flex flex-col" data={layoutData}>
      <Banner bannerData={pageData} />
      <Solution solutionData={pageData} />
      <Features featuresData={pageData} />
      <Pricing pricingData={pageData} />
      <About aboutData={pageData} />
    </Layout>
  );
};

export const query = graphql`
  query Homepage($lang: String) {
    ...Footer
    ...Navigation
    ...Global
    prismicHomepage(lang: { eq: $lang }) {
      id
      lang
      type
      url
      data {
        ...Banner
        ...Solution
        ...Features
        ...Pricing
        ...About
      }
    }
  }
`;

export default Homepage;

export const Head: HeadFC = () => <Seo />;

import classNames from "classnames";
import { graphql } from "gatsby";
import React from "react";
import FeatureItem from "./feature-item";

export interface FeaturesProps {
  featuresData: Queries.FeaturesFragment;
}

const Features: React.FC<FeaturesProps> = ({ featuresData }) => {
  const {
    features_title: featuresTitle,
    features_description: featuresDescription,
    feature_item: featureItems,
  } = featuresData;
  const title = featuresTitle?.text;
  const description = featuresDescription?.text;
  const descriptionAccent = featuresDescription?.text;

  const FeatureItems = featureItems?.map((item, index) => {
    return (
      <FeatureItem
        key={index}
        className="flex items-start"
        text={item?.feature_item_text?.text!}
        imageAlt={item?.feature_item_icon?.alt}
        imageUrl={item?.feature_item_icon?.fixed?.src!}
      />
    );
  });
  return (
    <section className="bg-neutral anchor" id="features">
      <div className="container mx-auto">
        <h2 className="mt-8 uppercase text-primary text-3xl font-medium md:mt-16">
          {title}
        </h2>
        <p className="mt-8 text-black lg:w-3/4">{description}</p>
        <div className="mt-8 mb-8 grid grid-cols-1 md:grid-cols-2 md:mb-16 lg:grid-cols-3 grid-flow-row gap-8">
          {FeatureItems}
        </div>
      </div>
    </section>
  );
};

export const queryFragment = graphql`
  fragment Features on PrismicHomepageDataType {
    features_title {
      text
    }
    features_description {
      text
    }
    feature_item {
      feature_item_text {
        text
      }
      feature_item_icon {
        alt
        fixed {
          src
        }
      }
    }
  }
`;

export default Features;

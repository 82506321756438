import classNames from "classnames";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { cleanImage } from "../../utils/image-utils";

export interface SolutionProps {
  solutionData: Queries.SolutionFragment;
}

const Solution: React.FC<SolutionProps> = ({ solutionData }) => {
  const {
    solution_title,
    solution_paragraph_description,
    solution_paragraph_accent,
    solution_image: image,
  } = solutionData;
  const title = solution_title?.text;
  const description = solution_paragraph_description?.text;
  const description_accent = solution_paragraph_accent?.text;
  const fluidImage = cleanImage(image?.gatsbyImageData);
  return (
    <section className="anchor" id="solution">
      <div className="container mx-auto flex flex-wrap items-center">
        <div className="w-full md:w-1/2 py-8 md:py-16 md:pr-8">
          <h2 className="uppercase text-primary text-3xl font-medium">
            {title}
          </h2>
          <p className="mt-8 text-black">{description}</p>
          <p className="mt-4 text-primary-700 font-medium text-xl">
            {description_accent}
          </p>
        </div>
        <div className="w-full md:w-1/2 pt-0 pb-8 md:py-16">
          {fluidImage && (
            <GatsbyImage
              image={fluidImage}
              className="max-h-[32rem]"
              imgStyle={{ objectFit: "contain" }}
              alt={image?.alt || ""}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export const queryFragment = graphql`
  fragment Solution on PrismicHomepageDataType {
    solution_image {
      alt
      gatsbyImageData
    }
    solution_paragraph_accent {
      text
    }
    solution_paragraph_description {
      text
    }
    solution_title {
      text
    }
  }
`;
export default Solution;

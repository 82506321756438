export function cleanImage(data: any) {
  const image = data?.images?.fallback;
  const keys = ["src", "srcWebp", "srcSet", "srcSetWebp"];
  keys.map((key) => {
    if (image && image[key]) {
      image[key] = image[key].replaceAll(
        "&auto=compress%2Cformat",
        "&auto=format"
      );
    }
  });
  return data;
}

import classNames from "classnames";
import { navigate } from "gatsby";
import React, { useCallback } from "react";
import navigation from "./navigation";

export interface MenuItemProps {
  link: string;
  onClick?: () => void;
}

const MenuItem: React.FC<React.PropsWithChildren<MenuItemProps>> = ({
  children,
  link,
  onClick = () => {},
}) => {
  const handleClick = useCallback(() => {
    navigate(link, { replace: true });
    onClick();
  }, [onClick, link]);
  return (
    <div
      onClick={handleClick}
      className="w-full py-4 px-4 text-center font-medium leading-6 text-primary-700 cursor-pointer hover:bg-neutral-400 hover:text-primary hover:rounded lg:w-auto"
    >
      {children}
    </div>
  );
};

export default MenuItem;

import classNames from "classnames";
import { graphql } from "gatsby";
import React, { useState } from "react";
import linksService from "../../services/links.service";
import PricingCard from "./pricing-card";
import PricingSelector, { PricingOption } from "./pricing-selector";
import PricingTrial from "./pricing-trial";

export interface PricingProps {
  pricingData: Queries.PricingFragment;
}

const Pricing: React.FC<PricingProps> = ({ pricingData }) => {
  const {
    pricing_title: pricingTitle,
    pricing_description: pricingDescription,
    pricing_title_pricing: pricingDetailsTitle,
    pricing_text_trial,
    pricing_text_trial_description,
    pricing_button_trial: buttonTrial,
    pricing_contact_link_text: pricingContactLinkText,
    pricing_contact_link_text_accent: pricingContactLinkTextAccent,
    pricing_tabs: tabs,
    pricing_card: cards,
  } = pricingData;
  const title = pricingTitle?.text;
  const description = pricingDescription?.text;
  const contactLinkText = pricingContactLinkTextAccent?.text;
  const contactText = pricingContactLinkText?.text;

  const [selectedKey, setSelectedKey] = useState<string>(
    tabs![0]?.tab_group_key!
  );

  const pricingOptions = tabs!.map((tab) => ({
    title: tab?.tab_group_title?.text!,
    key: tab?.tab_group_key!,
  }));
  const selectedCards = cards
    ?.filter((card) => card && card.pricing_tab_group_key === selectedKey)
    .map((card) => {
      return (
        <PricingCard
          className="max-w-[22.5rem] w-full"
          key={card?.card_title}
          title={card?.card_title!}
          promotion={card?.discount_sum}
          description={card?.card_description!}
          price={card?.price!}
          priceDescription={card?.price_description!}
          buttonText={card?.button_text!}
        />
      );
    });
  return (
    <section className="bg-primary anchor" id="pricing">
      <div className="container mx-auto">
        <div className="mx-auto md:w-3/4 mt-8 md:mt-16">
          <h2 className="uppercase text-white text-center text-3xl font-medium">
            {title}
          </h2>
          <p className="mt-8 text-white font-medium text-xl text-center">
            {description}
          </p>
        </div>
        <h3 className="mt-4 md:mt-8 uppercase text-white text-center text-2xl font-medium">
          {pricingDetailsTitle}
        </h3>
        <PricingSelector
          selectedKey={selectedKey}
          onKeySelected={(item) => setSelectedKey(item)}
          className="mt-4 justify-center"
          pricingOptions={pricingOptions}
        />
        <div className="flex flex-col items-center justify-center gap-8 mt-11 lg:flex-row">
          {selectedCards}
        </div>
        <div className="mt-8 mb-8 md:mb-16 text-center text-white p-8 lg:border lg:border-white lg:rounded">
          <a
            href={linksService.getContactUsAddress()}
            className="underline font-medium hover:text-confirm"
          >
            {contactLinkText}
          </a>
          &nbsp;{contactText}
        </div>
      </div>
    </section>
  );
};

export const queryFragment = graphql`
  fragment Pricing on PrismicHomepageDataType {
    pricing_tabs {
      tab_group_key
      tab_group_title {
        text
      }
    }
    pricing_title {
      text
    }
    pricing_title_pricing
    ...PricingTrial
    pricing_description {
      text
    }
    pricing_contact_link_text_accent {
      text
    }
    pricing_contact_link_text {
      text
    }
    pricing_card {
      button_text
      card_description
      card_title
      discount_sum
      price
      price_description
      pricing_tab_group_key
    }
  }
`;
export default Pricing;

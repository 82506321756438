import classNames from "classnames";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { PrismicRichText } from "@prismicio/react";
import linksService from "../../services/links.service";
import { cleanImage } from "../../utils/image-utils";
import LogoSigoor from "../../images/logos/logo-sigoor.png";
import LogoMercury from "../../images/logos/logo-mercury.png";

export interface AboutProps {
  aboutData: Queries.AboutFragment;
}

const About: React.FC<AboutProps> = ({ aboutData }) => {
  const {
    about_title: aboutTitle,
    about_description: aboutDescription,
    about_image: image,
  } = aboutData;
  const title = aboutTitle?.text;
  const description = aboutDescription?.richText;
  const fluidImage = cleanImage(image?.gatsbyImageData);
  return (
    <section className="bg-neutral anchor" id="about">
      <div className="container mx-auto grid grid-cols-1 grid-flow-row gap-8 lg:grid-cols-2">
        <div className="pt-8 lg:py-16 place-self-center">
          <h2 className="uppercase text-primary text-3xl font-medium">
            {title}
          </h2>
          <PrismicRichText
            field={description}
            components={{
              paragraph: ({ children }) => {
                const Components = children.map((child, index) => (
                  <p key={index} className="mt-8 font-medium text-primary-700">
                    {child}
                  </p>
                ));
                return <>{Components}</>;
              },
            }}
          />
          <div className="mt-4 md:mt-8 flex gap-4 flex-col items-center sm:flex-row">
            <a
              target="_blank"
              className="p-4 hover:bg-neutral-400 border border-neutral-700 rounded"
              href={linksService.getMercuryUrl()}
            >
              <img src={LogoMercury} alt="Mercury AG logo" className="h-8" />
            </a>
            <a
              target="_blank"
              className="p-4 hover:bg-neutral-400 border border-neutral-700 rounded"
              href={linksService.getSigoorUrl()}
            >
              <img src={LogoSigoor} alt="Sigoor AG logo" className="h-8" />
            </a>
          </div>
        </div>
        <div className="py-8 lg:py-16">
          <GatsbyImage
            image={fluidImage!}
            className="max-h-[32rem] object-cover object-center"
            imgStyle={{ objectFit: "contain" }}
            alt={image?.alt || ""}
          />
        </div>
      </div>
    </section>
  );
};

export const queryFragment = graphql`
  fragment About on PrismicHomepageDataType {
    about_title {
      text
    }
    about_image {
      alt
      gatsbyImageData
    }
    about_description {
      text
      richText
    }
  }
`;
export default About;

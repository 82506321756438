import classNames from "classnames";
import React from "react";
import linksService from "../../services/links.service";

export interface PricingCardProps {
  className?: string;
  promotion?: string | null;
  title: string;
  description: string;
  buttonText: string;
  price: string;
  priceDescription: string;
}

const PricingCard: React.FC<PricingCardProps> = ({
  className,
  promotion,
  title,
  description,
  buttonText,
  price,
  priceDescription,
}) => {
  return (
    <div
      className={classNames(
        "bg-white min-w-[16rem] flex flex-col items-center rounded relative hover:ring-4 hover:ring-confirm",
        className
      )}
    >
      {promotion && (
        <div className="bg-info rounded text-primary-700 font-medium text-xs px-1 py-1 -translate-y-1/2 absolute mx-auto">
          {promotion}
        </div>
      )}

      <h4 className="mt-8 font-medium text-primary text-xl">{title}</h4>
      <h5 className="font-medium text-primary">{description}</h5>
      <hr className="mt-4 bg-primary h-0.5 w-full" />
      <h3 className="mt-4 text-primary font-medium text-5xl">{price}</h3>
      <div className="mt-2 mb-8 text-sm text-primary-700">
        {priceDescription}
      </div>
    </div>
  );
};

export default PricingCard;

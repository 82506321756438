import classNames from "classnames";
import { graphql } from "gatsby";
import React from "react";
import { GatsbyImage, withArtDirection, getImage } from "gatsby-plugin-image";
import IconNext from "../../images/icons/next.svg";
import linksService from "../../services/links.service";
import { cleanImage } from "../../utils/image-utils";

export interface BannerProps {
  bannerData: Queries.BannerFragment;
}

const Banner: React.FC<BannerProps> = ({ bannerData }) => {
  const {
    banner_title,
    banner_subtitle,
    banner_button,
    banner_image: image,
  } = bannerData;
  const title = banner_title?.text;
  const description = banner_subtitle?.text;
  const buttonText = banner_button?.text;

  const imageData = cleanImage(image?.gatsbyImageData);
  const imageMobile = cleanImage(image?.thumbnails?.mobile?.gatsbyImageData);
  const images = withArtDirection(imageData, [
    {
      media: "(max-width: 800px)",
      image: imageMobile,
    },
  ]);
  return (
    <section className="relative">
      {images && (
        <GatsbyImage
          image={images}
          className="object-cover object-right h-[32rem] w-full"
          alt={image?.alt || ""}
        />
      )}
      <div className="w-full absolute top-0 left-0 bottom-0 right-0 flex items-center">
        <div className="container mx-auto">
          <h1 className="text-white text-4xl font-medium break-words">
            {title}
          </h1>
          <h2 className="mt-2 text-white leading-8 text-2xl font-normal">
            {description}
          </h2>
        </div>
      </div>
    </section>
  );
};

export const queryFragment = graphql`
  fragment Banner on PrismicHomepageDataType {
    banner_title {
      text
    }
    banner_subtitle {
      text
    }
    banner_button {
      text
    }
    banner_image {
      alt
      gatsbyImageData(srcSetMinWidth: 800)
      thumbnails {
        mobile {
          gatsbyImageData
        }
      }
    }
  }
`;

export default Banner;

import classNames from "classnames";
import { graphql, Link } from "gatsby";
import React, { useCallback, useRef, useState } from "react";
import MenuClosed from "../../images/icons/menu_closed.svg";
import MenuOpen from "../../images/icons/menu_open.svg";
import logo from "../../images/logos/cc-logo.svg";
import { ActivePageModel } from "../../models/active-page.model";
import linksService from "../../services/links.service";
import { useEscapeClick } from "../../services/use-escape-click";
import { useOutsideClick } from "../../services/use-outside-click";
import LanguagePickerDropdown from "../languages/language-picker-dropdown";
import LanguagePickerMenu from "../languages/language-picker-menu";
import MenuItem from "./menu-item";

export interface NavigationProps {
  className?: string;
  activePage: ActivePageModel;
  navigation: Queries.NavigationFragment | null;
  global: Queries.GlobalFragment | null;
}

const Navigation: React.FC<NavigationProps> = ({
  className,
  activePage,
  navigation,
  global,
}) => {
  const [hidden, setHidden] = useState<boolean>(true);
  const toggleHidden = useCallback(() => {
    setHidden(!hidden);
  }, [hidden, setHidden]);
  const hideMenu = useCallback(() => {
    setHidden(true);
  }, [setHidden]);
  const rootRef = useRef<HTMLDivElement>(null);
  useOutsideClick(rootRef, hideMenu);
  useEscapeClick(rootRef, hideMenu);
  const dividerClassName = "lg:hidden w-full h-px text-neutral-700";
  const navigationData = navigation?.prismicNavigation?.data;
  const NavigationLinks = navigationData?.navigation_links?.reduce(
    (collector, item, index) => {
      collector.push(<hr key={`hr-${index}`} className={dividerClassName} />);
      collector.push(
        <MenuItem key={index} link={item?.anchor!} onClick={hideMenu}>
          {item?.title}
        </MenuItem>
      );
      return collector;
    },
    new Array<React.ReactNode>()
  );
  return (
    <nav ref={rootRef} className={classNames("bg-white w-full", className)}>
      <div className="min-h-20 container mx-auto flex flex-col items-stretch relative lg:flex-row">
        <div className="flex items-center self-stretch">
          <Link to="/" className="py-4">
            <img className="w-44" src={logo} alt="Comply check logo" />
          </Link>
          <button
            onClick={toggleHidden}
            type="button"
            className="w-11 h-11 inline-flex ml-auto items-center justify-center rounded-md p-2 hover:bg-neutral-400 focus:outline-none lg:hidden"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <MenuClosed
              className={classNames("w-5 h-5 text-primary-700 fill-current", {
                hidden: !hidden,
              })}
            />
            <MenuOpen
              className={classNames("w-3 h-3 text-primary-700 fill-current", {
                hidden: hidden,
              })}
            />
            <span className="sr-only">Open menu</span>
          </button>
        </div>

        <div
          className={classNames(
            "items-stretch py-4 flex flex-col",
            { hidden: hidden },
            "lg:flex lg:flex-row lg:ml-auto lg:items-center"
          )}
        >
          {NavigationLinks}
          <LanguagePickerDropdown
            selectedLanguage={activePage.lang}
            global={global}
            className="ml-4 hidden lg:inline-block"
          />
          <hr className={dividerClassName} />
          <LanguagePickerMenu
            selectedLanguage={activePage.lang}
            global={global}
            className="my-4 lg:hidden"
          />
        </div>
      </div>
    </nav>
  );
};

export const navigationData = graphql`
  fragment NavigationData on PrismicNavigation {
    data {
      button_login
      button_signup
      navigation_links {
        anchor
        title
      }
    }
  }
`;
export const navigation = graphql`
  fragment Navigation on Query {
    prismicNavigation(lang: { eq: $lang }) {
      ...NavigationData
    }
  }
`;

export default Navigation;

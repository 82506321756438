import { RefObject, useEffect } from "react";

export const useEscapeClick = (
  ref: RefObject<HTMLDivElement>,
  callable: () => any
) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleEvent(event: KeyboardEvent) {
      if (ref?.current && event.key === "Escape") {
        callable();
      }
    }

    // Bind the event listener
    document.addEventListener("keydown", handleEvent);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("keydown", handleEvent);
    };
  }, [ref, callable]);
};

import classNames from "classnames";
import React from "react";

export interface FeatureItemProps {
  className?: string;
  text: string;
  imageUrl: string;
  imageAlt?: string | null;
}

const FeatureItem: React.FC<FeatureItemProps> = ({
  className,
  text,
  imageUrl,
  imageAlt,
}) => {
  return (
    <div
      className={classNames(
        "h-28 bg-white text-primary-700 p-4 fill-current rounded shadow",
        className
      )}
    >
      <img
        alt={imageAlt || ""}
        src={imageUrl}
        className="w-12 h-12 fill-current inline-block"
      />
      <p className="ml-4 font-medium inline-block">{text}</p>
    </div>
  );
};

export default FeatureItem;

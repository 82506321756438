import classNames from "classnames";
import React from "react";

export interface PricingSelectorProps {
  className?: string;
  pricingOptions: PricingOption[];
  selectedKey: string;
  onKeySelected: (key: string) => void;
}

export interface PricingOption {
  title: string;
  key: string;
}

const PricingSelector: React.FC<PricingSelectorProps> = ({
  className,
  pricingOptions,
  selectedKey,
  onKeySelected,
}) => {
  const PricingOptions = pricingOptions.map((item) => {
    return (
      <div
        key={item.key}
        onClick={() => onKeySelected(item.key)}
        className={classNames(
          "pt-4 text-white text-opacity-40 font-medium w-40 text-center cursor-pointer hover:bg-white hover:bg-opacity-40",
          {
            "text-opacity-100": selectedKey == item.key,
          }
        )}
      >
        <span>{item.title}</span>
        <div
          className={classNames("mt-3 h-1 bg-white bg-opacity-40 rounded-l", {
            "bg-opacity-100": selectedKey == item.key,
          })}
        ></div>
      </div>
    );
  });
  return <div className={classNames("flex", className)}>{PricingOptions}</div>;
};

export default PricingSelector;
